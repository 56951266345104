<template>
	<main class="single terms">
		<div class="terms-background">
			<section class="form">
				<header class="heading">
					<h1>{{ i18n.t('Tucows Tiered Access Compliance and Operations System Terms of Service') }}</h1>
				</header>
				<p>
					{{ i18n.t('The Tiered Access Compliance and Operations (TACO) system is operated by Tucows.com, Co. on behalf of participating registrars. Users are allocated to a particular tier and provided access to relevant domain names and data elements; the TACO system will display current registration data.') }}
				</p>

				<p>{{ i18n.t('The TACO system displays previously-public registration data. For domains that use any of our Whois privacy services, adequate due process (a warrant or subpoena, domesticated into Canada, Denmark, Germany, or the United States) must be submitted before any additional data will be disclosed. The TACO system presents, on an as-is basis, data gathered from and verified by the registrant and validated by the relevant registrar through existing ICANN processes. Tucows makes no representations and provides no warranty regarding the accuracy of the data.') }}</p>

				<p>
					{{ i18n.t('Registration data disclosure requests may be submitted via email and should follow the RrSG\'s ') }} <a
						class="icann"
						href="https://rrsg.org/wp-content/uploads/2019/02/RrSG-Minimum-Required-Information-for-a-Whois-Data-Requests.pdf"
					>Minimum Required Information for Whois Data Requests</a>
					{{ i18n.t('. This document lays out the required format and content for requests: to be clear, each of the 7 questions must be answered. In addition, please indicate what data elements you are requesting for each domain. Incomplete requests will not be processed; incomplete requests will delay processing; repeated failure to submit appropriate and complete requests may result in suspension of future submission rights. Each request is individually reviewed by a Tucows staff member for sufficiency, to determine legitimacy of access, and to determine appropriate tier of disclosure. Tucows appreciates your patience while this review is conducted.') }}</p>
				<p>
					{{ i18n.t('We will respond via email through our ticketing system; our turnaround time is based on the volume and complexity of requests.') }}
				</p>

				<p>{{ i18n.t('Submission of a request for disclosure of current registration data constitutes:') }}</p>
				<ul>
					<li>{{ i18n.t('acceptance of this Terms of Service;') }}</li>
					<li>{{ i18n.t('agreement to disclose your request and any relevant data to the data subject(s) in question at Tucows’ sole discretion;') }}</li>
					<li>{{ i18n.t('agreement that you will only use disclosed data for the purpose identified in your request;') }}</li>
					<li>{{ i18n.t('agreement that your request for disclosure of data is related to a lawful purposes;') }}</li>
					<li>{{ i18n.t('agreement that your request and information will be supplied to the data subject as part of our required disclosures; and') }}</li>
					<li>{{ i18n.t('agreement that under no circumstances will you use this data to allow, enable, or otherwise support the transmission of mass unsolicited, commercial advertising or solicitations via email, telephone, or facsimile.') }}</li>
				</ul>

				<p>{{ i18n.t('The following are considered abuse and may result in termination of access to the whole TACO system:') }}</p>
				<ul>
					<li>{{ i18n.t('Attempts to access or query the TACO system through the use of electronic processes that are high-volume or automated.') }}</li>
					<li>{{ i18n.t('Sharing issued credentials with any other party, including within your own company.') }}</li>
					<li>{{ i18n.t('Misrepresentations of any kind, including in individual requests for disclosure.') }}</li>
					<li>{{ i18n.t('Compilation, repackaging, dissemination, or other use of disclosed data.') }}</li>
					<li>{{ i18n.t('Use of any data you receive to allow, enable, or otherwise support the transmission of mass unsolicited, commercial advertising or solicitations via email, telephone, or facsimile.') }}</li>
					<li>{{ i18n.t('Other abuse in our sole determination.') }}</li>
				</ul>
				<p>{{ i18n.t('Reinstatement following termination due to abuse will be considered but is not guaranteed. Tucows reserves the right to restrict your access to the TACO system in its sole discretion. Tucows reserves the right to modify these terms at any time; your continued use of the TACO system after modification indicates your agreement to abide by these terms.') }}</p>
				<p>{{ i18n.t('Tucows may charge TACO requestors a fee to cover the costs of operating the TACO platform and reviewing requests. This fee is generally waived for single-use and non-commercial requestors. The standard fee schedule is:')}}</p>
				<ul>
					<li>{{ i18n.t('$500.00 account creation fee')}}</li>
					<li>{{ i18n.t('$250.00 monthly fee (includes 5 lookups)')}}</li>
					<li>{{ i18n.t('$50.00 per lookup above 5')}}</li>
				</ul>
				<p>{{ i18n.t('Please note that ') }} 
					<span class="bold">{{ i18n.t('this is a cost-recovery fee for human review of all submissions and not a revenue-generating service.') }}</span>
					{{ i18n.t(' “Lookups” includes')}}
					<span class="bold">{{ i18n.t('all ') }}</span>
					{{ i18n.t('attempts to access previously-public Whois data, not only approved domains. Reasonable mistakes (such as selecting the wrong registrar option) will not be penalized but note that multiple lookups for the same domain and attempted lookups of unapproved domains will result in fees. Failure to pay within 14 days of invoicing will result in suspension of services.')}}</p>

				<p>{{ i18n.t('In order to provide the highest level of service, it is sometimes necessary for Tucows to use third party services. Here is a list of the third party services that Tucows uses in connection with the TACO system:')}}</p>
				<ul>
					<li>{{ i18n.t('Atlassian (Jira)')}}</li>
					<li>{{ i18n.t('Datadog')}}</li>
					<li>{{ i18n.t('Freshworks')}}</li>
					<li>{{ i18n.t('Google Workspace')}}</li>
					<li>{{ i18n.t('Radware WAF')}}</li>
					<li>{{ i18n.t('Recorded Future')}}</li>
					<li>{{ i18n.t('SolDevelo')}}</li>
					<li>{{ i18n.t('Stripe')}}</li>
					<li>{{ i18n.t('Zendesk')}}</li>
				</ul>
				<p>{{ i18n.t('In addition, in order to comply with our obligations under GDPR, your information may be transmitted to the data subject. This includes the name and contact data from your account, any reasoning you provide for requesting access, as well as the fact that the disclosure occurred.')}}</p>
				<h5><u>{{ i18n.t('Cookies') }}</u></h5>
				<p>{{ i18n.t('Tucows uses only functional cookies on tieredaccess.com to identify you after log in and to keep you logged in.') }}</p>
			</section>
		</div>
	</main>
</template>

<script>
export default {name: 'Terms'};
</script>

<style lang="less" scoped>
	@import (reference) "theme";

	.terms-background {
		display: flex;
		justify-content: center;
		width: 100%;
		background: @grey1_lighter;
	}

	.heading {
		padding: 0 0 @p20px 0;
	}

	.form {
		max-width: @sectionMaxWidth;
	}

	ul {
		padding-left: @p20px;
		margin-bottom: @p20px;
		line-height: @p20px;
		list-style: disc;
	}

	.information {
		padding-top: @p20px;
	}
</style>
